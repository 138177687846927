import React from "react";
import "../Marker/ComponentPetals.css";

interface ComponentPetalsProps {
  numberEvent: any[];
  clusterInfoLocationHistory: any[];
  isLarge?: string;
}

export const ComponentPetals: React.FC<ComponentPetalsProps> = ({
  numberEvent,
  clusterInfoLocationHistory,
  isLarge = "",
}) => {
  function handleStatusColor(status: string) {
    switch (status) {
      case "WARNING":
        return "var(--global-colors-feedback-warning)";
      case "INFO":
        return "var(--global-colors-ui-primary)";
      case "ALARM":
        return "var(--global-colors-feedback-danger)";
      default:
        return "transparent";
    }
  }

  function handleStatusColorForNumber(status: string) {
    switch (status) {
      case "WARNING":
        return "var(--global-colors-ink-dark)";
      case "INFO":
        return "var(--global-colors-ui-white)";
      case "ALARM":
        return "var(--global-colors-ui-white)";
      default:
        return "transparent";
    }
  }

  function placeNumberTop(index: number, eventLength: number) {
    if (eventLength === 2) {
      switch (index) {
        case 0:
          return 25;
        case 1:
          return 25;
      }
    } else if (eventLength === 3) {
      switch (index) {
        case 0:
          return 30;
        case 1:
          return 11;
        case 2:
          return 30;
      }
    }
  }

  function placeNumberLeft(index: number, eventLength: number) {
    if (eventLength === 2) {
      switch (index) {
        case 0:
          return 21;
        case 1:
          return 78;
      }
    } else if (eventLength === 3) {
      switch (index) {
        case 0:
          return 16;
        case 1:
          return 48;
        case 2:
          return 81;
      }
    }
  }

  function getEventValue(eventDict: any, eventType: string) {
    if (eventDict.hasOwnProperty(eventType)) {
      return eventDict[eventType].length;
    } else {
      return null;
    }
  }

  return (
    <div
      className={`component-petals${isLarge}`}
      style={
        numberEvent.length === 2
          ? {
              background: `conic-gradient(from 270deg, ${handleStatusColor(
                numberEvent[0]
              )} 0% 25%, ${handleStatusColor(
                numberEvent[1]
              )} 25% 50%, rgba(0, 82, 189, 0.1) 50% 100%)`,
            }
          : {
              background: `conic-gradient(from 270deg, ${handleStatusColor(
                numberEvent[0]
              )} 0% 16.67%, ${handleStatusColor(
                numberEvent[1]
              )} 16.67% 33.33%, ${handleStatusColor(
                numberEvent[2]
              )} 33.33% 50%, rgba(0, 82, 189, 0.1) 50% 100%)`,
            }
      }
    >
      {numberEvent.map((event, index) => (
        <div
          key={index}
          className="event-label"
          style={{
            color: handleStatusColorForNumber(event),
            position: "absolute",
            top: `${placeNumberTop(index, numberEvent.length)}%`,
            left: `${placeNumberLeft(index, numberEvent.length)}%`,
            transform: "translate(-50%, -50%)",
            fontSize: "10px",
            fontWeight: 500,
          }}
        >
          {getEventValue(clusterInfoLocationHistory, event)}
        </div>
      ))}
    </div>
  );
};
