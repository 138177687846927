import React from "react";
import { IconKey } from "../../../ui/Icon/Solid/Key";

export interface IgnitionKeyLineProps {
  color: string; // Tipizzato correttamente
  ignitionKey: boolean[]; // Array booleano
  period: string[]; // Array di stringhe
}

export const IgnitionKeyLine: React.FC<IgnitionKeyLineProps> = ({
  color,
  ignitionKey,
  period,
}) => {
  const generateIgnitionKeyLine = (
    ignitionKey: boolean[],
    period: string[]
  ) => {
    const segments = [];
    let startIndex = 0;

    for (let i = 1; i <= ignitionKey.length; i++) {
      if (ignitionKey[i] !== ignitionKey[i - 1] || i === ignitionKey.length) {
        segments.push({
          isContinuous: ignitionKey[i - 1], // Continua o tratteggiata
          startIndex,
          endIndex: i - 1,
        });
        startIndex = i;
      }
    }
    return segments;
  };

  const segments = generateIgnitionKeyLine(ignitionKey, period);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "row",
        alignItems: "center",
        width: "calc(100% - 100px)",
        height: "1px", // Altezza della linea
        marginTop: "10px", // Distanza dal grafico
        marginLeft: "25px",
      }}
    >
      <div style={{ marginRight: "30px" }}>
        <IconKey size={14} color={color} />
      </div>
      {segments.map((segment, index) => {
        const segmentLength = segment.endIndex - segment.startIndex + 1;
        const totalLength = ignitionKey.length;
        const widthPercentage = (segmentLength / totalLength) * 100;

        return (
          <div
            key={index}
            style={{
              width: `${widthPercentage}%`,
              height: "100%",
              backgroundColor: segment.isContinuous
                ? "var(" + color + ")"
                : "transparent",
              borderStyle: segment.isContinuous ? "solid" : "dashed",
              borderWidth: "2px", // Più spessa
              borderColor: "var(" + color + ")",
              borderRadius: segment.isContinuous ? "0" : "5px", // Opzione per arrotondare i bordi
            }}
          ></div>
        );
      })}
    </div>
  );
};
