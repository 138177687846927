import { t } from "i18next";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAppSelector } from "../../../app/hooks";
import "./AnalogicReportChart.css";
import { selectAnalogicReportChartDataSliceStatus } from "./analogicReportChartDataSlice";
import { IgnitionKeyLine } from "./IgnitionKeyLine";

export interface AnalogicReportChartProps {
  ignitionKey: boolean[];
  temperature: number[];
  humidity: number[];
  period: any[];
  thresholdMax: number;
  thresholdMin: number;
}

export const AnalogicReportChart: React.FC<AnalogicReportChartProps> = ({
  ignitionKey,
  temperature,
  humidity,
  period,
  thresholdMax,
  thresholdMin,
}) => {
  //FORMATTERS SECTION
  var temperatureFormatter = function (value: any) {
    let val = value.toFixed(1) + " °";
    return val;
  };
  var humidityFormatter = function (value: any) {
    let val = value + " %";
    return val;
  };

  //FUNCTIONS SECTION

  function calculateMaxForGraph() {
    if (!temperature || temperature?.length === 0) {
      return 0;
    }

    let maxTemperature = Math.max(...temperature);
    if (maxTemperature > thresholdMax) {
      return maxTemperature + 10;
    } else {
      return thresholdMax + 10;
    }
  }

  function calculateMinForGraph() {
    if (!temperature || temperature?.length === 0) {
      return 0;
    }

    let minTemperature = Math.min(...temperature);
    if (minTemperature < thresholdMin) {
      return minTemperature - 10;
    } else {
      return thresholdMin - 10;
    }
  }

  //OPTIONS FOR CHART SECTION

  const [options, setOptions] = useState<any>({
    chart: {
      toolbar: {
        show: false,
      },
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#0052BD", "#00AAFF"],
    series: [
      {
        name: t("report.analogic.temperature"),
        data: temperature,
      },
      {
        name: t("report.analogic.humidity"),
        data: humidity,
      },
    ],
    stroke: {
      width: [2, 2],
    },
    zoom: {
      enabled: false,
    },
    xaxis: {
      categories: period,
      tickAmount: 8,
      labels: {
        rotate: 0,
        style: {
          transform: "none",
        },
      },
    },
    yaxis: [
      {
        seriesName: t("report.analogic.temperature"),
        axisBorder: {
          show: true,
        },
        max: calculateMaxForGraph(),
        min: calculateMinForGraph(),
        labels: { formatter: temperatureFormatter },
      },
      {
        opposite: true,
        seriesName: t("report.analogic.humidity"),
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        max: 100,
        min: 0,
        labels: { formatter: humidityFormatter },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({
        series,
        dataPointIndex,
        w,
      }: {
        series: number[][];
        dataPointIndex: number;
        w: any;
      }) {
        if (dataPointIndex === undefined) {
          return null;
        }
        const chartRect = w.globals.dom.baseEl?.getBoundingClientRect();
        const scalingFactor = 1;
        const positions = series.map((_, idx) => {
          const seriesName = w.globals.seriesNames[idx];
          if (seriesName.toLowerCase().includes("threshold")) {
            return null;
          }

          const point = w.globals.pointsArray[idx]?.[dataPointIndex];
          if (!point) return null;
          const distanceFromStart =
            (point[0] / w.globals.svgWidth) * chartRect.width;
          const correctedX =
            chartRect.left +
            distanceFromStart -
            scalingFactor * distanceFromStart +
            window.scrollX;
          const y = chartRect.top + point[1] - 10 + window.scrollY;
          const value = series[idx][dataPointIndex];
          const color = w.globals.colors[idx];

          return {
            name: seriesName,
            value,
            color,
            x: correctedX,
            y,
          };
        });

        const filteredPositions = positions.filter((pos) => pos !== null);

        return `
          <div style="position: relative; pointer-events: none;">
            ${filteredPositions
              .map(
                (pos) =>
                  pos &&
                  `
                  <div style="
                    position: none;
                    left: ${pos?.x}px;
                    top: ${pos?.y - 250}px;
                    background: ${
                      pos?.name !== t("report.analogic.humidity") &&
                      (pos?.value > thresholdMax || pos?.value < thresholdMin)
                        ? "#FF4F48"
                        : pos?.color
                    };
                    color: white;
                    height: 30px;
                    padding: 5px;
                    border-radius: 5px;
                    border: 1px solid ${
                      pos?.name !== t("report.analogic.humidity") &&
                      (pos?.value > thresholdMax || pos?.value < thresholdMin)
                        ? "#FF4F48"
                        : pos?.color
                    };
                    transform: translate(${
                      pos?.name === t("report.analogic.humidity")
                        ? "-50%, -100%"
                        : "-50%, 250%"
                    });
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                  ">
                    <p style="
                    height: fit-content;
                    display: flex; 
                    flex-direction: row;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;
                    ">${pos?.name}: ${pos?.value.toString().slice(0, 5)} ${
                    pos?.name === t("report.analogic.humidity") ? "%" : "°"
                  }</p>
                  </div>
                `
              )
              .join("")}
          </div>`;
      },
    },
    annotations: {
      yaxis: [
        thresholdMax != null &&
          thresholdMax != undefined && {
            y: thresholdMax,
            borderColor: "#FF0000",
            strokeDashArray: 0,
          },
        thresholdMin != null &&
          thresholdMin != undefined && {
            y: thresholdMin,
            borderColor: "#FF0000",
            strokeDashArray: 0,
          },
      ],
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
      show: false,
    },
  });

  //REFRESH CHART SECTION
  const loader = useAppSelector(selectAnalogicReportChartDataSliceStatus);
  useEffect(() => {
    if (temperature.length > 0 && humidity.length > 0 && period.length > 0) {
      const newOptions = {
        chart: {
          toolbar: {
            show: false,
          },
          type: "line",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#0052BD", "#00AAFF"],
        series: [
          {
            name: t("report.analogic.temperature"),
            data: temperature,
          },
          {
            name: t("report.analogic.humidity"),
            data: humidity,
          },
        ],
        stroke: {
          width: [2, 2],
        },
        zoom: {
          enabled: false,
        },
        xaxis: {
          categories: period,
          tickAmount: 8,
          labels: {
            rotate: 0,
            style: {
              transform: "none",
            },
          },
        },
        yaxis: [
          {
            seriesName: t("report.analogic.temperature"),
            axisBorder: {
              show: true,
            },
            max: calculateMaxForGraph(),
            min: calculateMinForGraph(),
            labels: { formatter: temperatureFormatter },
          },
          {
            opposite: true,
            seriesName: t("report.analogic.humidity"),
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            max: 100,
            min: 0,
            labels: { formatter: humidityFormatter },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          custom: function ({
            series,
            dataPointIndex,
            w,
          }: {
            series: number[][];
            dataPointIndex: number;
            w: any;
          }) {
            if (dataPointIndex === undefined) {
              return null;
            }

            const chartRect = w.globals.dom.baseEl?.getBoundingClientRect();
            const scalingFactor = 1;

            const positions = series.map((_, idx) => {
              const seriesName = w.globals.seriesNames[idx];
              if (seriesName.toLowerCase().includes("threshold")) {
                return null;
              }

              const point = w.globals.pointsArray[idx]?.[dataPointIndex];
              if (!point) return null;

              const distanceFromStart =
                (point[0] / w.globals.svgWidth) * chartRect.width;
              const correctedX =
                chartRect.left +
                distanceFromStart -
                scalingFactor * distanceFromStart +
                window.scrollX;
              const y = chartRect.top + point[1] - 10 + window.scrollY;
              const value = series[idx][dataPointIndex];
              const color = w.globals.colors[idx];

              return {
                name: seriesName,
                value,
                color,
                x: correctedX,
                y,
              };
            });

            const filteredPositions = positions.filter((pos) => pos !== null);

            if (filteredPositions.length === 0) {
              return null;
            }

            // Calcolo posizione tooltip generale
            const averageX =
              filteredPositions.reduce((sum, pos) => sum + (pos?.x ?? 0), 0) /
              filteredPositions.length;
            const averageY =
              filteredPositions.reduce((sum, pos) => sum + (pos?.y ?? 0), 0) /
              filteredPositions.length;

            return `
              <div style="
                position: absolute;
                left: ${averageX}px;
                top: ${averageY - 200}px;
                transform: translate(-50%, -100%);
                background: white;
                border-radius: 8px;
                border: 1px solid #ddd;
                padding: 10px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                pointer-events: none;
                white-space: nowrap;
              ">
                ${filteredPositions
                  .map(
                    (pos) =>
                      pos &&
                      `
                      <div style="
                        display: flex;
                        align-items: center;
                        margin-bottom: 5px;
                        color: ${
                          pos.name !== t("report.analogic.humidity") &&
                          (pos.value > thresholdMax || pos.value < thresholdMin)
                            ? "#FF4F48"
                            : pos.color
                        };
                      ">
                        <span style="
                          width: 12px;
                          height: 12px;
                          background: ${
                            pos.name !== t("report.analogic.humidity") &&
                            (pos.value > thresholdMax ||
                              pos.value < thresholdMin)
                              ? "#FF4F48"
                              : pos.color
                          };
                          border-radius: 50%;
                          margin-right: 8px;
                        "></span>
                        <p style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: 600;
                        ">
                          ${pos.name}: ${pos.value.toString().slice(0, 5)} ${
                        pos.name === t("report.analogic.humidity") ? "%" : "°"
                      }
                        </p>
                      </div>
                    `
                  )
                  .join("")}
              </div>`;
          },
        },

        annotations: {
          yaxis: [
            thresholdMax != null &&
              thresholdMax != undefined && {
                y: thresholdMax,
                borderColor: "#FF0000",
                strokeDashArray: 0,
              },
            thresholdMin != null &&
              thresholdMin != undefined && {
                y: thresholdMin,
                borderColor: "#FF0000",
                strokeDashArray: 0,
              },
          ],
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
          show: false,
        },
      };
      if (!_.isEqual(newOptions, options)) {
        setOptions(newOptions);
      }
    }
  }, [temperature, humidity, period, thresholdMax, thresholdMin]);
  const isDataAvailable =
    (temperature.length > 0 || humidity.length > 0) && period.length > 0;
  return (
    <div className="analogic-report-graph">
      {loader === "loading" && <div className="analogic-report-graph-loader" />}
      <div className="analogic-report-key-engine-statics"></div>
      {isDataAvailable && !_.isEmpty(options) ? (
        <>
          <IgnitionKeyLine
            color={"--global-colors-palette-green"}
            ignitionKey={ignitionKey}
            period={period}
          />
          <div className="analogic-report-apex-chart">
            <ReactApexChart
              options={options}
              series={options.series}
              type="line"
              height={250}
            />
          </div>
        </>
      ) : (
        <div className="analogic-report-apex-chart">
          <ReactApexChart
            options={{
              chart: {
                type: "line",
                height: 250,
                animations: { enabled: false },
              },
              xaxis: { categories: [] },
              yaxis: {},
              series: [],
            }}
            series={[]}
            type="line"
            height={250}
          />
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              color: "gray",
            }}
          >
            {t("report.analogic.empty.chart")}
          </div>
        </div>
      )}
    </div>
  );
};
