import dayjs from "dayjs";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Location,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { Button } from "../../../ui/Button/Button";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import { VehiclesDropdown } from "../../../ui/Forms/VehiclesDropdown";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconList } from "../../../ui/Icon/Line/List";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { IconSpeed } from "../../../ui/Icon/Line/Speed";
import { IconVehicle } from "../../../ui/Icon/Line/Vehicle";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getQueryString } from "../../../utils/Utils";
import { getAddressBooksAsync } from "../../address/addressBookSlice";
import { fleetViewsSelectors } from "../../fleet/fleetViewsSlice";
import { RouteStateType } from "../../route/routesHistorySlice";
import { schedulesSelectors } from "../../schedules/schedulesSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { getPresetsAsync } from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import {
  Vehicle,
  getVehiclesDetailsAsync,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../../vehicle/vehiclesSlice";
import ReportsRepository from "../reportRepository";
import { getReportsMetadataAsync } from "../reportsMetadataSlice";
import { SchedulationManageModal } from "../schedulation/SchedulationManageModal";
import { SchedulationModal } from "../schedulation/SchedulationModal";
import "./GPSDataReportFilterBar.css";
import {
  getGPSDataReportsAsync,
  gpsDataReportsEmptyState,
  selectGPSDataReportsSliceReasonCode,
  selectGPSDataReportsSliceStatus,
} from "./gpsDataReportSlice";
import { getGPSDataReportsSummaryAsync } from "./gpsDataReportsSummarySlice";

interface QueryParams {
  [paramName: string]: any;
}

interface GPSDataReportFilterBarProps {
  callback: (buildQueryParam: string) => any;
  setVehiclesNumber: (e: any) => any;
  routeType: RouteStateType;
  setRouteState: (e: any) => any;
  disableButton?: boolean;
  setOpenSpeedGraph?: (e: any) => any;
}

const routePointContext = "gpsDataReportRoutePoint";
const stopContext = "gpsDataReportStop";
const reportsRepository = new ReportsRepository();

export const GPSDataReportFilterBar: React.FC<GPSDataReportFilterBarProps> = ({
  callback,
  setVehiclesNumber,
  routeType,
  setRouteState,
  disableButton,
  setOpenSpeedGraph,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
  const vehicleSliceStatus = useAppSelector(selectVehiclesSliceStatus);

  const reportsSliceStatus = useAppSelector(selectGPSDataReportsSliceStatus);
  const reportsSliceReasonCode = useAppSelector(
    selectGPSDataReportsSliceReasonCode
  );

  const pathMatchAddingSchedulation = useMatch(
    "/reports/gps-data/addSchedulation"
  );
  const pathMatchEditingSchedulation = useMatch(
    "/reports/gps-data/editSchedulation/:schedulationId"
  );

  const [schedulationModal, setSchedulationModal] = useState(
    !!pathMatchAddingSchedulation || !!pathMatchEditingSchedulation
  );

  const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);
  const [vehiclesId, setVehiclesId] = useState<number[]>([]);

  const [isDisabledGenerate, setDisabledGenerate] = useState(true);
  const [isSelectedDate, setIsSelectedDate] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<Date[]>([]);
  const [routeStateType, setRouteStateType] = useState<RouteStateType>("TRACK");
  const [queryString, setQueryString] = useState("");
  const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
  const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [manageModalOpened, setManageModalOpened] = useState(false);
  const schedules = useAppSelector(schedulesSelectors.selectAll);
  const filterSchedulesByType = (type: string) => {
    return schedules.filter((schedule: any) => schedule.type === type);
  };

  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  let isReportsIdle = reportsSliceStatus === "idle";

  // Dispatch for list of vehicles
  useEffect(() => {
    store.dispatch(getVehiclesDetailsAsync());
    store.dispatch(
      getPresetsAsync(
        getQueryString({ context: [routePointContext, stopContext] })
      )
    );
    store.dispatch(getAddressBooksAsync(""));
    return () => {
      store.dispatch(vehiclesEmptyState());
    };
  }, []);

  //#region " get group (fleet) of vehicles "
  let vehiclesGroupByFleet = vehicles.reduce((group: any, vehicle: Vehicle) => {
    const { fleet } = vehicle;
    const fleetName = fleetViewsSelectors.selectById(
      store.getState(),
      fleet
    )?.name;
    if (fleetName) {
      group[fleetName] = group[fleetName] ?? [];
      group[fleetName].push(vehicle);
    }
    return group;
  }, {});

  let fleetNames = Object.keys(vehiclesGroupByFleet);
  //#endregion

  let vehiclesOptions = fleetNames?.map((fleetName: string) => ({
    label: fleetName,
    hasCheckbox: true,
    hasDropdown: true,
    hasCount: true,
    vehicles: vehiclesGroupByFleet[fleetName],
  }));

  //#region ERROR_HANDLING
  useEffect(() => {
    if (reportsSliceStatus === "failed" && reportsSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [reportsSliceStatus, reportsSliceReasonCode]);
  //#endregion
  useEffect(() => {
    schedulationModal && setManageModalOpened(false);
  }, [schedulationModal]);
  useEffect(() => {
    manageModalOpened && setSchedulationModal(false);
  }, [manageModalOpened]);
  /**
   * If the user selects a different route type, the filter bar is notified and handles the change.
   */
  useEffect(() => {
    const map = new Map();
    if (routeType !== routeStateType) {
      setRouteStateType(routeType);
      map.set("routeType", routeType);
      handleChanges(map);
    }
  }, [routeType]);

  useEffect(() => {
    GenerateResult();
  }, [routeStateType]);

  useEffect(() => {
    setDisabledGenerate(
      !(queryParams && isSelectedDate && vehicleList.length > 0)
    );
  }, [queryString, isSelectedDate, vehicleList]);

  const handleChanges = (
    params: Map<string, string[] | string>,
    generateImmediatly?: boolean
  ): void => {
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
          }
        }
      });
      setQueryString(getQueryString(queryParams));
      const stringifiedQueryString = getQueryString(queryParams);
      pathMatchAddingSchedulation === null &&
        pathMatchEditingSchedulation === null &&
        navigate(`/reports/gps-data${stringifiedQueryString}`);
      pathMatchAddingSchedulation &&
        navigate(`/reports/gps-data/addSchedulation${stringifiedQueryString}`);
      pathMatchEditingSchedulation &&
        navigate(
          `/reports/gps-data/editSchedulation/${pathMatchEditingSchedulation.params.schedulationId}${stringifiedQueryString}`
        );

      generateImmediatly &&
        GenerateResultWithParam(stringifiedQueryString, vehicleList);
    }
    queryParams && isSelectedDate && setDisabledGenerate(false);
  };

  const GenerateResult = () => {
    if (queryString !== "" && vehicleList.length > 0 && isSelectedDate) {
      GenerateResultWithParam(queryString, vehicleList);
    }
  };
  const GenerateResultWithParam = (
    queryString: string,
    vehicleList: Vehicle[]
  ) => {
    store.dispatch(
      getGPSDataReportsAsync({
        queryParams: queryString,
        page: 1,
        routeType: routeType,
      })
    );
    store.dispatch(getGPSDataReportsSummaryAsync(queryString));
    setVehiclesNumber(vehicleList?.length);
    setDisabledGenerate(false);
    callback(queryString);
  };

  const downloadResult = (pdf: boolean) => {
    if ((vehicleList?.length !== 0 && queryString !== "") || isSelectedDate) {
      const params = queryString + (pdf ? "&isPdf=true" : "&isPdf=false");
      pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
      reportsRepository
        .getGpsDataReportDownload(params)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          const fileName = data.split("/").pop();
          const attachmentDownloadLink = document.createElement("a");
          attachmentDownloadLink.href = process.env.REACT_APP_BUCKET_URL + data;
          attachmentDownloadLink.download = fileName;
          attachmentDownloadLink.target = "_blank";
          attachmentDownloadLink.style.display = "none";
          document.body.appendChild(attachmentDownloadLink);
          attachmentDownloadLink.click();
          document.body.removeChild(attachmentDownloadLink);
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
        })
        .catch((error) => {
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          if (
            error.response &&
            error.response.data.message ==
              GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
          ) {
            console.log(error?.message || "Report Timeout Exception");
            ToastNotification({
              toastId: "reportTimeoutError",
              status: "default",
              title: t("common.reportTimeoutErrorTitle"),
              description: t("common.reportTimeoutErrorDesc"),
            });
          } else {
            console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.tryLaterError"),
            });
          }
        });
    }
  };

  const sendReportEmail = () => {
    if ((vehicleList?.length !== 0 && queryString !== "") || isSelectedDate) {
      reportsRepository
        .getGpsDataReportEmail(queryString)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          ToastNotification({
            toastId: "requestSendingMailSuccess",
            status: "success",
            description: t(
              "report.toastNotification.requestSendingMailSuccess"
            ),
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error?.message || "Unknown Error");
          else console.log(error.response?.data?.error || "Unknown Error");
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        });
    }
  };

  //#region " checking parameters in the url "
  const [searchParams] = useSearchParams();
  const location: Location = useLocation();

  useEffect(() => {
    pathMatchEditingSchedulation && setSchedulationModal(true);
  }, [pathMatchEditingSchedulation]);

  /**
   * This useEffect takes the vehicles identifiers retrieved from the URL and based
   * on the downloaded vehicles details builds the vehicle list (vehicleList hook).
   */
  useEffect(() => {
    if (
      vehiclesId &&
      vehiclesId.length > 0 &&
      vehicleSliceStatus === "idle" &&
      vehicles.length > 0 &&
      vehicleList.length === 0
    ) {
      const selectedVehicles: Vehicle[] = [];
      vehiclesId.forEach((x) => {
        const selectedVehicle =
          vehiclesSelectors.selectById(store.getState(), x ?? -1) ??
          ({} as Vehicle);
        selectedVehicle &&
          !_.isEmpty(selectedVehicle) &&
          selectedVehicles.push(selectedVehicle);
      });
      setVehicleList(selectedVehicles);
    }
  }, [vehiclesId, vehicles]);

  useEffect(() => {
    let generateImmediatly = false;
    const map = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      // opening speed report modal
      const openSpeedReport = currentSearchParams.get("openSpeedGraph");
      if (openSpeedReport) {
        setOpenSpeedGraph && setOpenSpeedGraph(true);
      }

      // retrieving ids from URL
      generateImmediatly = true;
      const vehicleIds = currentSearchParams.getAll("vehicleIds");
      generateImmediatly =
        generateImmediatly && vehicleIds && vehicleIds.length > 0;
      if (!!vehicleIds) {
        if (vehicleIds?.length > 0) {
          setVehiclesId(vehicleIds.map((el) => parseInt(el)));
          map.set(
            "vehicleIds",
            vehicleIds.map((el) => el)
          );
        }
      }

      const startPeriod = currentSearchParams.get("startPeriod");
      generateImmediatly = generateImmediatly && !!startPeriod;
      const endPeriod = currentSearchParams.get("endPeriod");
      if (startPeriod) {
        map.set("startPeriod", startPeriod);
        setIsSelectedDate(true);
        setDisabledGenerate(false);
        setSelectedPeriod((prev) => {
          let prevValue = prev;

          prevValue[0] = new Date(startPeriod);

          return prevValue;
        });
      }
      if (endPeriod) {
        map.set("endPeriod", endPeriod);
        setSelectedPeriod((prev) => {
          let prevValue = prev;

          prevValue[1] = new Date(endPeriod);

          return prevValue;
        });
      }

      const routeType = currentSearchParams.get("routeType");
      if (routeType) {
        map.set("routeType", routeType);
        setRouteState(routeType);
      }
    }

    if (map.size === 0 && _.isEmpty(queryParams)) {
      const endPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
      const startPeriodDate = new Date(endPeriod);
      startPeriodDate.setHours(0, 0, 0, 0);
      const startPeriod = dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
      map.set("startPeriod", startPeriod);
      map.set("endPeriod", endPeriod);
      setIsSelectedDate(true);
      setSelectedPeriod((prev) => {
        let prevValue = prev;
        prevValue[0] = new Date(startPeriod);
        prevValue[1] = new Date(endPeriod);
        return prevValue;
      });
      map.set("routeType", "TRACK");
      setRouteState("TRACK");
    }

    if (map.size > 0) {
      handleChanges(map, generateImmediatly);
    }
  }, [location]);
  //#endregion
  const list = [
    {
      id: 0,
      title: t("report.filterBar.downloadExcel"),
      icon: isExcelGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(false);
      },
    },
    {
      id: 1,
      title: t("report.filterBar.downloadPDF"),
      icon: isPdfGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(true);
      },
    },
    {
      id: 2,
      title: t("report.filterBar.sendReportEmail"),
      icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
      onClick: () => {
        sendReportEmail();
      },
    },
  ];

  return (
    <div className="gpsd-report-filter-row">
      <div className="gpsd-report-filter-vehicles-dropdown">
        <VehiclesDropdown
          loading={vehicleSliceStatus === "loading"}
          options={vehiclesOptions}
          vehiclesLimit={20}
          getValueSelected={(values: Vehicle[]) => {
            if (!_.isEqual(values, vehicleList)) {
              const map = new Map();
              if (values && values?.length > 0) {
                map.set(
                  "vehicleIds",
                  values.map((x) => x.id)
                );
                setVehicleList(values);
              } else {
                map.set("vehicleIds", []);
                setVehicleList([]);
              }
              handleChanges(map);
            }
          }}
          valueSelected={vehicleList}
        />
      </div>
      <div className="gpsd-report-filter-datepicker">
        <Form>
          <DatePickerDropdown
            icon={<IconCalendar size={12} color="--global-colors-ink-light" />}
            hasTime={true}
            setDate={(val) => {
              const map = new Map();
              const startPeriod =
                typeof val[0] === "string"
                  ? val[0]
                  : val[0].format("YYYY-MM-DDTHH:mm");
              map.set("startPeriod", startPeriod);
              map.set("endPeriod", val[1]?.format("YYYY-MM-DDTHH:mm") ?? "");
              setIsSelectedDate(true);
              handleChanges(map);
              vehicleList?.length !== 0
                ? setDisabledGenerate(false)
                : setDisabledGenerate(true);
            }}
            clearCallback={() => {
              setIsSelectedDate(false);
              setDisabledGenerate(true);
              const map = new Map();
              const startPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
              map.set("startPeriod", startPeriod);
              handleChanges(map);
            }}
            dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
            limitDaysRange={7}
            initialValue={selectedPeriod.length ? selectedPeriod : []}
          />
        </Form>
      </div>
      <div className="gps-report-filter-btn-generate">
        <Button
          aspect="primary"
          size="small"
          label={t("report.filterBar.buttonGenerate")}
          onClick={() => {
            store.dispatch(gpsDataReportsEmptyState());
            GenerateResult();
          }}
          isLoading={!isReportsIdle}
          disabled={isDisabledGenerate}
        />
      </div>
      <div className="gps-report-filter-btn">
        <Button
          id="speedGraph"
          disabled={disableButton}
          aspect="outline"
          onClick={() => {
            setOpenSpeedGraph && setOpenSpeedGraph(true);
          }}
          label={t("report.filterBar.openSpeedGraph")}
          size="small"
        >
          <IconSpeed size={14} color="--global-colors-ink-ink" />
        </Button>
        <DropdownButton
          aspect="secondary"
          size="small"
          label={t("report.filterBar.schedule")}
          onClick={() =>
            store.dispatch(
              getReportsMetadataAsync("?lang=" + preferencesContext.language)
            )
          }
          disabled={false}
          list={
            [
              {
                id: 0,
                title: t("common.schedulate"),
                icon: <IconAdd size={14} color={"#687484"} />,
                onClick: () => {
                  setSchedulationModal(!schedulationModal);
                },
              },
              {
                id: 0,
                title: t("common.schedulationList"),
                icon: <IconList size={14} color={"#687484"} />,
                onClick: () => {
                  setManageModalOpened(true);
                },
                disabled:
                  filterSchedulesByType("GPSDATA_STOP_REPORT").length === 0,
              },
            ] as any
          }
        >
          <IconCalendar size={14} color="--global-colors-ink-ink" />
        </DropdownButton>
        <DropdownButton
          aspect="secondary"
          disabled={disableButton}
          size="small"
          label={t("common.export")}
          list={list}
        >
          <IconDown size={14} color="--global-colors-ink-ink" />
        </DropdownButton>
      </div>
      {schedulationModal && (
        <SchedulationModal
          reportType="GPSDATA_STOP_REPORT"
          schedulationId={
            pathMatchEditingSchedulation
              ? pathMatchEditingSchedulation.params.schedulationId
              : undefined
          }
          onClose={() => {
            setSchedulationModal(false);
          }}
          isOpen={schedulationModal}
          customEntities={vehicles.map((vehicle) => {
            return {
              title: vehicle.alias,
              id: vehicle.id,
            };
          })}
          customEntitiesParamName="vehicleIds"
          customTextLabel={t("common.selectVehicles")}
          customIcon={<IconVehicle size={14} />}
        />
      )}
      <SchedulationManageModal
        schedulationAddModal={(e) => setSchedulationModal(e)}
        reportType={"GPSDATA_STOP_REPORT"}
        isOpen={manageModalOpened}
        onClose={() => {
          setManageModalOpened(false);
        }}
        schedulations={filterSchedulesByType("GPSDATA_STOP_REPORT")}
      />
    </div>
  );
};
