import { Loader } from "@googlemaps/js-api-loader";
import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { SingleDatePicker } from "../../ui/Forms/SingleDatePicker";
import TextField from "../../ui/Forms/TextField";
import { IconUpload } from "../../ui/Icon/Line/Upload";
import { DataCommon } from "../../utils/Common";
import { ToastNotification } from "../../utils/ToastNotification";
import { autoCompleteAddressFields } from "../../utils/Utils";
import { UploadImage } from "../components/UploadImage";
import {
  FleetView,
  fleetViewsEmptyState,
  fleetViewsSelectors,
  getFleetViewsAsync,
  selectFleetViewsSliceReasonCode,
  selectFleetViewsSliceStatus,
} from "../fleet/fleetViewsSlice";
import {
  Preferences,
  selectPreferencesSliceReasonCode,
  selectPreferencesSliceStatus,
} from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  Vehicle,
  getVehiclesDetailsAsync,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import "./AdminDriverManagement.css";
import {
  Driver,
  createDriverAsync,
  driversEmptyState,
  driversSelectors,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
} from "./driversSlice";

interface CreateDriverProps {
  permissions: UserPermissions;
}

interface DataCommonProps {
  label: string;
}

export interface AddressInfo {
  address: string;
  city: string;
  province: string;
  zip: string;
  country: string;
}

const CreateDriver: React.FC<CreateDriverProps> = ({ permissions }) => {
  const navigate = useNavigate();

  const [editDriver, setEditDriver] = useState<Driver>({} as Driver);

  const driversSliceStatus = useAppSelector(selectDriversSliceStatus);
  const driversSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);
  const fleetsSliceStatus = useAppSelector(selectFleetViewsSliceStatus);
  const fleetsSliceReasonCode = useAppSelector(selectFleetViewsSliceReasonCode);
  const vehiclesSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const vehiclesSliceReasonCode = useAppSelector(selectVehiclesSliceReasonCode);
  const preferencesSliceStatus = useAppSelector(selectPreferencesSliceStatus);
  const preferencesSliceReasonCode = useAppSelector(
    selectPreferencesSliceReasonCode
  );
  const prefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);
  const licenseTypeList: DataCommonProps[] =
    DataCommon()?.licenseTypeList ?? ([] as DataCommonProps[]);
  const driverRoute = useMatch("/admin/drivers/new");
  const [openModalAvatar, setOpenModalAvatar] = useState(false);

  const vehicle: Vehicle =
    useAppSelector((state) =>
      vehiclesSelectors.selectById(state, editDriver?.vehicle ?? -1)
    ) ?? ({} as Vehicle);
  let drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
  let vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
  let fleetViews: FleetView[] = useAppSelector(fleetViewsSelectors.selectAll);
  let fleetView: FleetView =
    useAppSelector((state) =>
      fleetViewsSelectors.selectById(state, editDriver?.fleet)
    ) ?? ({} as FleetView);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const [usernameAlreadyExists, setUsernameAlreadyExists] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [disabledFields, setDisabledFields] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const [addressInfoOnAutoComplete, setAddressInfoOnAutoComplete] =
    useState<AddressInfo>({} as AddressInfo);

  /**
   * This useEffect handles the updating of the address info once the user
   * select an address from the autocompletion functionality.
   * It solves a bug. The callback function of the autocompletion
   * does not keep memory of the state of the editing/creating driver.
   */
  useEffect(() => {
    if (!_.isEmpty(addressInfoOnAutoComplete)) {
      setEditDriver({
        ...editDriver,
        address: addressInfoOnAutoComplete.address,
        city: addressInfoOnAutoComplete.city,
        province: addressInfoOnAutoComplete.province,
        zip: addressInfoOnAutoComplete.zip,
        country: addressInfoOnAutoComplete.country,
      });
    }
  }, [addressInfoOnAutoComplete]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env?.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
      libraries: ["places", "drawing", "geometry"],
      version: "beta",
    });
    loader.load().then();
    return () => {
      loader.deleteScript();
    };
  }, []);

  useEffect(() => {
    // get vehicle id and fleet name
    if (driverRoute !== null) {
      store.dispatch(getFleetViewsAsync());
      store.dispatch(getVehiclesDetailsAsync());
    }
  }, [navigate, driverRoute]);

  useEffect(() => {
    if (
      vehiclesSliceStatus === "failed" &&
      vehiclesSliceReasonCode === GTFleetErrorCodes.VEHICLE_NOT_FOUND
    ) {
      ToastNotification({
        toastId: "vehicleNotFoundError",
        status: "error",
        description: t("common.vehicleNotFoundError"),
      });
    } else if (
      vehiclesSliceStatus === "failed" &&
      vehiclesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehiclesSliceStatus, vehiclesSliceReasonCode]);

  useEffect(() => {
    if (fleetsSliceStatus === "failed" && fleetsSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
      navigate(-1);
    }
  }, [fleetsSliceStatus, fleetsSliceReasonCode, navigate]);

  useEffect(() => {
    if (
      preferencesSliceStatus === "failed" &&
      preferencesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [preferencesSliceStatus, preferencesSliceReasonCode]);

  useEffect(() => {
    if (
      driversSliceStatus === "failed" &&
      driversSliceReasonCode === GTFleetErrorCodes.USER_ALREADY_EXISTS
    ) {
      setUsernameAlreadyExists(true);
      ToastNotification({
        toastId: "usernameAlreadyExists",
        status: "error",
        description:
          editDriver && editDriver?.username + " " + t("common.alreadyExists"),
      });
    } else if (
      driversSliceStatus === "failed" &&
      driversSliceReasonCode === GTFleetErrorCodes.DRIVER_ALREADY_EXISTS
    ) {
      setEmailAlreadyExists(true);
      ToastNotification({
        toastId: "emailAlreadyExists",
        status: "error",
        description:
          editDriver && editDriver?.email + " " + t("common.alreadyExists"),
      });
    } else if (
      driversSliceStatus === "idle" &&
      driversSliceReasonCode === GTFleetSuccessCodes.POST
    ) {
      ToastNotification({
        toastId: "driverSuccess",
        status: "success",
        description: t("common.driverSuccess"),
      });
      let id =
        drivers.find((driver) => driver.email === editDriver?.email)?.id ??
        undefined;
      navigate(id ? "/admin/drivers/view/" + id : "/admin/drivers");
    } else if (
      driversSliceStatus === "failed" &&
      driversSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [drivers, driversSliceReasonCode, driversSliceStatus, navigate]);

  const getNullInitialValue = () => {
    return Object.values(editDriver).length === 0 ? null : "";
  };

  useEffect(() => {
    document.title =
      t("admin.driver.editViewCreate.createAccount") + " - Admin";
    return () => {
      store.dispatch(driversEmptyState());
      store.dispatch(fleetViewsEmptyState());
      store.dispatch(vehiclesEmptyState());
    };
  }, []);

  function hasNullValues(obj: object): boolean {
    return Object.values(obj).every((value) => value === "" || null);
  }

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <div className="drvr-subtopbar">
            <div className="drvr-subsection-name">
              {t("admin.driver.editViewCreate.createAccount")}
            </div>
            <div className="drvr-buttons">
              <Button
                size="small"
                aspect="secondary"
                label={t("common.cancel")}
                onClick={() => navigate("/admin/drivers")}
              />
              <Button
                size="small"
                aspect="primary"
                disabled={
                  errorList.length !== 0 ||
                  Object.values(editDriver).length === 0 ||
                  hasNullValues(editDriver)
                }
                label={t("common.save")}
                onClick={() => {
                  Object.values(editDriver).length > 0 &&
                    !hasNullValues(editDriver) &&
                    store.dispatch(createDriverAsync({ driver: editDriver }));
                }}
              ></Button>
            </div>
          </div>
        </div>
      </PageFilters>
      <PageContent>
        <Form>
          <div className="drvr-page-container">
            {/* General */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.general")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">
                    {t("common.name")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item">
                      <TextField
                        id="Name"
                        validate="alphabetic"
                        placeholder={t("common.name")}
                        value={editDriver?.firstName ?? getNullInitialValue()}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditDriver({
                              ...editDriver,
                              firstName: data.value ?? undefined,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.surname")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id="Surname"
                        validate="alphabetic"
                        placeholder={t("common.surname")}
                        value={editDriver?.lastName ?? getNullInitialValue()}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditDriver({
                              ...editDriver,
                              lastName: data.value ?? undefined,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("common.address")}
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <SearchField
                      id="Address"
                      placeholder={t("common.address")}
                      onChange={(data) => {
                        setEditDriver({
                          ...editDriver,
                          address: data ?? undefined,
                        });
                      }}
                      value={editDriver.address}
                      searchAddress={true}
                      hasSearchIcon={true}
                      onAddressClick={(data) => {
                        const addressInfo = autoCompleteAddressFields(
                          data,
                          setDisabledFields
                        );
                        setAddressInfoOnAutoComplete(addressInfo);
                      }}
                      autocompleteOptions={{
                        types: ["route"],
                        componentRestrictions: { country: "it" },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">
                    {t("common.city")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item">
                      <TextField
                        id="City"
                        validate="alphabetic"
                        placeholder={t("common.city")}
                        value={editDriver?.city ?? getNullInitialValue()}
                        disabled={disabledFields}
                      />
                    </div>
                  </div>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.province")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-name">
                      <TextField
                        id="Province"
                        validate="alphabetic"
                        placeholder={t("common.province")}
                        value={editDriver.province ?? getNullInitialValue()}
                        disabled={disabledFields}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.zip")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id="Cap"
                        validate="numeric"
                        placeholder={t("common.zip")}
                        value={editDriver?.zip ?? getNullInitialValue()}
                        disabled={disabledFields}
                        maxLength="5"
                      />
                    </div>
                  </div>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.country")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-right-forms-label">
                      <TextField
                        id="Country"
                        validate="alphabetic"
                        placeholder={t("common.country")}
                        value={editDriver.country ?? getNullInitialValue()}
                        disabled={disabledFields}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("common.email")} *
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-text-field">
                    <TextField
                      id="driver-email"
                      validate="email"
                      type="email"
                      placeholder={t("common.email")}
                      value={editDriver?.email ?? getNullInitialValue()}
                      errorList={setErrorList}
                      events={{
                        onChange: (data) =>
                          setEditDriver({
                            ...editDriver,
                            email: data.value ?? undefined,
                          }),
                      }}
                    >
                      {emailAlreadyExists ? (
                        <span className="drvr-margin-top drvr-input-error">
                          {t("admin.driver.editViewCreate.emailAlreadyExists")}
                        </span>
                      ) : (
                        <></>
                      )}
                    </TextField>
                  </div>
                  <div className="drvr-validate-button">
                    <Button
                      size="regular"
                      aspect="primary"
                      disabled={true}
                      label={t("common.validate")}
                    />
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">{t("common.phone")}</div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-phone">
                    <div className="drvr-drpdwn-small">
                      <Dropdown
                        isClearable={false}
                        placeholder={t("common.phone")}
                        value={{
                          label: editDriver?.phoneNumberPrefix ?? "+39",
                        }}
                        size="normal"
                        itemAttribute="label"
                        onChange={(val) => {
                          setEditDriver({
                            ...editDriver,
                            phoneNumberPrefix: val[0].label ?? undefined,
                          });
                        }}
                        options={prefixList}
                      />
                    </div>
                  </div>
                  <div className="drvr-right-form-phone">
                    <TextField
                      id="Number"
                      validate="numeric"
                      placeholder={t("common.phone")}
                      errorList={setErrorList}
                      value={editDriver?.phoneNumber ?? getNullInitialValue()}
                      events={{
                        onChange: (data) =>
                          setEditDriver({
                            ...editDriver,
                            phoneNumber: data.value ?? undefined,
                          }),
                      }}
                      maxLength="10"
                      minLength="9"
                    />
                  </div>

                  <div className="drvr-validate-button">
                    <Button
                      size="regular"
                      aspect="primary"
                      disabled={true}
                      label={t("common.validate")}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Personal info */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.personalInfo")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">
                    {t("common.dateOfBirth")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item">
                      <SingleDatePicker
                        id="1"
                        setErrorList={setErrorList}
                        errorList={errorList}
                        validation=""
                        dateRange={[dayjs().subtract(100, "year"), dayjs()]}
                        setDate={(data) => {
                          setEditDriver({
                            ...editDriver,
                            dateOfBirth: data ?? undefined,
                          });
                        }}
                        placeholder={t("common.dateOfBirth")}
                        localeFormat={
                          preferencesContext.localeFormat ?? "DD / MM / YYYY"
                        }
                        language={preferencesContext.language ?? "it"}
                      />
                    </div>
                  </div>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.fiscalCode")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id="fiscalCode"
                        validate="fiscalCode"
                        placeholder={t("common.fiscalCode")}
                        value={editDriver?.fiscalCode ?? getNullInitialValue()}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditDriver({
                              ...editDriver,
                              fiscalCode: data.value ?? undefined,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("admin.driver.editViewCreate.avatar")}
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <Button
                      aspect="secondary"
                      size="regular"
                      label={
                        editDriver?.avatar?.split("/").pop() ??
                        t("common.upload")
                      }
                      onClick={() => {
                        setOpenModalAvatar(true);
                      }}
                    >
                      <IconUpload className="" size={14} color="#687484" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* Driver License */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.driveLicense")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">
                    {t("admin.driver.editViewCreate.licenseId")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item">
                      <TextField
                        id="licenseId"
                        validate="alphaNumeric"
                        placeholder={t("admin.driver.editViewCreate.licenseId")}
                        value={editDriver?.licenseId ?? getNullInitialValue()}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditDriver({
                              ...editDriver,
                              licenseId: data.value ?? undefined,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("admin.driver.editViewCreate.licenseExpDate")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <SingleDatePicker
                        id="2"
                        setErrorList={setErrorList}
                        errorList={errorList}
                        dateRange={[
                          dayjs().subtract(10, "year"),
                          dayjs().add(25, "year"),
                        ]}
                        setDate={(data) => {
                          setEditDriver({
                            ...editDriver,
                            licenseExpirationDate: data ?? undefined,
                          });
                        }}
                        placeholder={t(
                          "admin.driver.editViewCreate.licenseExpDate"
                        )}
                        localeFormat={
                          preferencesContext.localeFormat ?? "DD / MM / YYYY"
                        }
                        language={preferencesContext.language ?? "it"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("admin.driver.editViewCreate.licenseType")}
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <Dropdown
                      isClearable={false}
                      itemAttribute="label"
                      placeholder={t("admin.driver.editViewCreate.licenseType")}
                      value={{
                        label: editDriver?.licenseType ?? undefined,
                      }}
                      size="normal"
                      onChange={(val) => {
                        setEditDriver({
                          ...editDriver,
                          licenseType: val[0].label ?? undefined,
                        });
                      }}
                      options={licenseTypeList}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Identification / Vehicle */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.identification")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("admin.driver.editViewCreate.driverId")}
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <TextField
                      id="driverId"
                      validate="numeric"
                      disabled={true}
                      placeholder={t("admin.driver.editViewCreate.driverId")}
                      value={editDriver?.id ?? getNullInitialValue()}
                    />
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("admin.driver.editViewCreate.linkedTachograph")}
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <TextField
                      id="tachographCard"
                      validate="alphaNumericSpecialCharacter"
                      placeholder={t(
                        "admin.driver.editViewCreate.linkedTachograph"
                      )}
                      value={
                        editDriver?.tachographCard ?? getNullInitialValue()
                      }
                      errorList={setErrorList}
                      events={{
                        onChange: (data) =>
                          setEditDriver({
                            ...editDriver,
                            tachographCard: data.value ?? undefined,
                          }),
                      }}
                      disabled={false}
                    >
                      <div className="drvr-tacograph-claim">
                        {t("admin.driver.editViewCreate.tachographClaim")}
                      </div>
                    </TextField>
                  </div>
                </div>
              </div>
              <div className="drvr-separator" />
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.associatedVehicle")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("common.vehicle")}
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    {vehicles.length > 0 && (
                      <Dropdown
                        isClearable={false}
                        itemAttribute="label"
                        placeholder={t(
                          "admin.driver.editViewCreate.selectVehicle"
                        )}
                        value={{
                          label: editDriver?.vehicle
                            ? vehicle?.alias + "-" + vehicle?.plate
                            : undefined,
                          id: editDriver?.vehicle ?? undefined,
                        }}
                        size="normal"
                        onChange={(val) => {
                          setEditDriver({
                            ...editDriver,
                            vehicle: val[0].id ?? undefined,
                          });
                        }}
                        options={vehicles.map((y) => {
                          const newObject = {
                            id: y.id,
                            label: y.alias + "-" + y.plate,
                          };
                          return newObject;
                        })}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* Group */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">{t("common.group")}</div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">{t("common.group")}</div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <Dropdown
                      isClearable={false}
                      itemAttribute="name"
                      placeholder={t("admin.driver.editViewCreate.selectGroup")}
                      value={{
                        name: fleetView.name ?? undefined,
                      }}
                      size="normal"
                      onChange={(val) => {
                        setEditDriver({
                          ...editDriver,
                          fleet: val[0].id,
                        });
                      }}
                      options={fleetViews}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Account */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">{t("common.account")}</div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("common.username")}
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item drvr-link">
                    <TextField
                      id="username"
                      placeholder={t("common.username")}
                      value={editDriver?.username ?? getNullInitialValue()}
                      events={{
                        onChange: (data) =>
                          setEditDriver({
                            ...editDriver,
                            username: data.value ?? undefined,
                          }),
                      }}
                    >
                      {usernameAlreadyExists ? (
                        <span className="drvr-margin-top drvr-input-error">
                          {t(
                            "admin.driver.editViewCreate.usernameAlreadyExists"
                          )}
                        </span>
                      ) : (
                        <></>
                      )}
                    </TextField>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </PageContent>
      <UploadImage
        openModal={openModalAvatar}
        setOpenModal={() => {
          setOpenModalAvatar(false);
        }}
        imageAvatar={
          (editDriver?.avatar && editDriver.avatar?.split("/").pop()) ?? ""
        }
        setUploadNameFile={(name) => {
          setEditDriver({
            ...editDriver,
            avatar: process.env.REACT_APP_BUCKET_URL + "Images/" + name,
          });
          ToastNotification({
            toastId: "titleMessageUploadingAvatar",
            status: "success",
            description: t(
              "account.settings.edit.descriptionMessageUploadingAvatar"
            ),
            title: t("account.settings.edit.titleMessageUploadingAvatar"),
          });
        }}
      />
    </>
  );
};
export default CreateDriver;
