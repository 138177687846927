import { t } from "i18next";
import { renderToString } from "react-dom/server";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { getIconFromVehicleType, sortByTimestamp } from "../../utils/Utils";
import { MapMarkerEventLocation } from "../Marker/MapMarkerEventLocation";

// #region Tooltip ArrowMarker
export const arrowInfoTooltipStyle = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        max-width: 260px !important;
        width: fit-content;
        background-color: var(--global-colors-ink-dark) !important;
        z-index: 1;
        position: absolute;
        color: var(--global-colors-ui-white);
        padding: 18px !important;
        border-radius: 4px;
        font-size: 12px !important;
        margin-top: 30px;
        font-family: var(--global-font-regular);
        cursor: var(--global-cursor-default);
        left: 0px;
        top: 0px;
        opacity: 0.9;
        min-width: 0px !important;
    }

    .gm-style .gm-style-iw-t::after {
        background: var(--global-colors-ink-dark);
        box-shadow: none !important;
        margin-top: 30px;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 0.9;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
        margin-top: 30px;
        background-color: var(--global-colors-ink-dark) !important;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 0.9;
    }
  `;
};
// #endregion Tooltip ArrowMarker

// #region Tooltip Public Route Point Info
export const publicRoutePointInfoStyle = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        width: fit-content !important;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        font-size: 12px !important;
        margin: 0px;
        padding: 0px !important;
        border-radius: 4px;
        color: var(--global-colors-ui-white);
        background-color: var(--global-colors-ink-dark) ;
        cursor: var(--global-cursor-default);
        opacity: 0.9;
        left: 0px;
        top: 0px;
        min-width: fit-content !important;
    }

    .gm-style .gm-style-iw-t::after {
      box-shadow: none ;
      margin-top: 0px;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
        display: flex;
        justify-content: center;
        width: -webkit-fill-available;
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 5px;
        color: white;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-t {
        bottom: 16px !important;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 0.9;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
        margin-top: 0px;
        background-color: var(--global-colors-ink-dark);
        width: 22px;
        height: 8px;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 0.9;
    }
  `;
};
// #endregion Tooltip Public Route Point Info

// #region Tooltip RouteInfoMarker
export const routeInfoTooltipStyle = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        font-size: 12px !important;
        margin: 0px;
        max-width: 260px !important;
        min-width: 260px !important;
        width: fit-content;
        padding: 18px !important;
        border-radius: 4px;
        color: var(--global-colors-ui-white);
        cursor: var(--global-cursor-default);
        background-color: var(--global-colors-ink-dark) !important;
        left: 0px;
        top: 0px;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-t {
        bottom: 46px !important;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 0.9;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
      margin-top: 0px;
      background-color: var(--global-colors-ink-dark) !important;
      width: 22px;
      height: 8px;
      opacity: 0.9;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-c .button {
        transition: none;
    }

    .gm-style .gm-style-iw-c .button:last-child {
        margin-left: auto;
    }
  `;
};
// #endregion Tooltip RouteInfoMarker

// #region Tooltip vehicles for fleet control
export const renderClusterVehiclesForFleetControl = (
  fleetControlClusterInfo
) => {
  return `
  <style>
  body {
    font-family: Arial, sans-serif;
  }

  .dashboard-container {
    background-color: var(--global-colors-ink-dark) !important;
    margin: 15px;
    margin-right: 0px;
  }

  .dashboard {
    overflow-y: scroll;
    overflow-x: hidden;
    width: auto;
    max-width: 720px;
    display: flex;
    gap: 25px;
    background-color: var(--global-colors-ink-dark);
    width: 100%;
    max-height: 120px;
    padding-bottom: 15px;
  }

  .dashboard-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 25px;
    padding-bottom: 15px;
    padding-right: 15px;
  }

  .fleettooltip-vehicles-elements-container {
    min-width: 120px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    width: 100%;
    cursor: var(--global-cursor-pointer) !important;
  }
  
  .fleettooltip-vehicles-elements-container > div{
    cursor: var(--global-cursor-pointer) !important;
  }

  .fleettooltip-vehicles-text-counter {
    color: var(--global-colors-ui-white);
    font-size: 10px;
    font-weight: 550;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  .fleettooltip-vehicles-counter-online {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    width: 100%;
    background-color: var(--global-colors-status-online);
    border-radius: 4px;
    height: 17px;
  }

  .fleettooltip-vehicles-counter-stop {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    width: 100%;
    background-color: var(--global-colors-ui-secondary);
    border-radius: 4px;
    height: 17px;
  }

  .fleettooltip-vehicles-counter-park {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    width: 100%;
    background-color: var(--global-colors-status-offline);
    border-radius: 4px;
    height: 17px;
  }

  .fleettooltip-vehicles-counter-offline {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    width: 100%;
    background-color: var(--global-colors-ink-light);
    border-radius: 4px;
    height: 17px;
  }
</style>

<div class="dashboard-container">
<div class="dashboard-header">
  ${
    fleetControlClusterInfo?.MOVING?.length > 0
      ? renderToString(
          <div class="fleettooltip-vehicles-counter-online">
            <div class="fleettooltip-vehicles-text-counter">
              {fleetControlClusterInfo?.MOVING?.length}
            </div>
          </div>
        )
      : renderToString(<></>)
  }
  ${
    fleetControlClusterInfo?.STOP?.length > 0
      ? renderToString(
          <div class="fleettooltip-vehicles-counter-stop">
            <div class="fleettooltip-vehicles-text-counter">
              {fleetControlClusterInfo?.STOP?.length}
            </div>
          </div>
        )
      : renderToString(<></>)
  }
  ${
    fleetControlClusterInfo?.PARKING?.length > 0
      ? renderToString(
          <div class="fleettooltip-vehicles-counter-park">
            <div class="fleettooltip-vehicles-text-counter">
              {fleetControlClusterInfo?.PARKING?.length}
            </div>
          </div>
        )
      : renderToString(<></>)
  }
  ${
    fleetControlClusterInfo?.OFFLINE?.length > 0
      ? renderToString(
          <div class="fleettooltip-vehicles-counter-offline">
            <div class="fleettooltip-vehicles-text-counter">
              {fleetControlClusterInfo?.OFFLINE?.length}
            </div>
          </div>
        )
      : renderToString(<></>)
  }
</div>
<div  class="dashboard">
${
  fleetControlClusterInfo?.MOVING?.length > 0
    ? renderToString(
        <div
          id="fleettooltip-vehicles-elements-container-moving"
          class="fleettooltip-vehicles-elements-container"
        >
          {fleetControlClusterInfo?.MOVING?.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  whiteSpace: "nowrap",
                  gap: "10px",
                  fontSize: "10px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
                class={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
                id={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
              >
                {getIconFromVehicleType(e?.vehicleInfo?.type, 10, "white")}
                <span>
                  {e?.vehicleInfo?.alias} • {e?.vehicleInfo?.plate}
                </span>
              </div>
            );
          })}
        </div>
      )
    : renderToString(<></>)
}
${
  fleetControlClusterInfo?.STOP?.length > 0
    ? renderToString(
        <div
          id="fleettooltip-vehicles-elements-container-stop"
          class="fleettooltip-vehicles-elements-container"
        >
          {fleetControlClusterInfo?.STOP?.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  whiteSpace: "nowrap",
                  gap: "10px",
                  fontSize: "10px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
                class={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
                id={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
              >
                {getIconFromVehicleType(e?.vehicleInfo?.type, 10, "white")}
                <span>
                  {e?.vehicleInfo?.alias} • {e?.vehicleInfo?.plate}
                </span>
              </div>
            );
          })}
        </div>
      )
    : renderToString(<></>)
}
${
  fleetControlClusterInfo?.PARKING?.length > 0
    ? renderToString(
        <div
          id="fleettooltip-vehicles-elements-container-parking"
          class="fleettooltip-vehicles-elements-container"
        >
          {fleetControlClusterInfo?.PARKING?.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  whiteSpace: "nowrap",
                  gap: "10px",
                  fontSize: "10px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
                class={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
                id={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
              >
                {getIconFromVehicleType(e?.vehicleInfo?.type, 10, "white")}
                <span>
                  {e?.vehicleInfo?.alias} • {e?.vehicleInfo?.plate}
                </span>
              </div>
            );
          })}
        </div>
      )
    : renderToString(<></>)
}
${
  fleetControlClusterInfo?.OFFLINE?.length > 0
    ? renderToString(
        <div
          id="fleettooltip-vehicles-elements-container-offline"
          class="fleettooltip-vehicles-elements-container"
        >
          {fleetControlClusterInfo?.OFFLINE?.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  whiteSpace: "nowrap",
                  gap: "10px",
                  fontSize: "10px",
                  marginRight: "1px",
                  cursor: "pointer",
                }}
                class={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
                id={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
              >
                {getIconFromVehicleType(e?.vehicleInfo?.type, 10, "white")}
                <span>
                  {e?.vehicleInfo?.alias} • {e?.vehicleInfo?.plate}
                </span>
              </div>
            );
          })}
        </div>
      )
    : renderToString(<></>)
}
</div>
</div>`;
};

export const tooltipVehiclesStyleForFleetControl = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        font-size: 12px !important;
        margin: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 0px !important;
        border-radius: 4px;
        color: var(--global-colors-ui-white);
        background-color: var(--global-colors-ink-dark);
        opacity: 0.9;
        left: 0px;
        top: 0px;
        cursor: var(--global-cursor-default);
        min-width: fit-content !important;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-t {
        bottom: 46px !important;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 0.9;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
        margin-top: 0px;
        background-color: var(--global-colors-ink-dark);
        width: 22px;
        height: 8px;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-c .button {
        transition: none;
    }

    .gm-style .gm-style-iw-c .button:last-child {
        margin-left: auto;
    }
  `;
};
// #endregion Tooltip vehicles for fleet control

// #region Tooltip event in location history

export const singleTooltipEventStyleForLocationHistory = () => {
  return `
   
      .gm-style .gm-style-iw-c,
      .gm-style .gm-style-iw-c:hover {
       background-color: #FFF !important;
       position: absolute;
       z-index: 1;
       font-family: var(--global-font-regular);
       color: var(--global-colors-ink-dark);
       margin-top: 0px;
       max-width: 300px !important;
       width: 200px;
       padding: 5px !important;
       border-radius: 4px;
       font-size: 12px !important;
       left: 95px;
       top: 62px;
       opacity: 1;
       min-width: fit-content !important;
       }

      .gm-style .gm-style-iw-t {
       bottom: 55px !important;
       }

      .gm-style .gm-style-iw-t::after {
       margin-top: 0px;
       font-size: 18px;
       }

      .gm-style .gm-style-iw-tc::after {
       background-color: transparent;
       margin-top: 15px;
       }

      .gm-style .gm-style-iw-tc:hover {
       background-color: transparent;
       }

      .gm-style .gm-style-iw-d {
       overflow: hidden !important;
       font-size: 15px;
       }`;
};

export const renderClusterEventForLocationHistory = (
  clusterEvent,
  preferences,
  allEvents
) => {
  const isOverflowHidden =
    clusterEvent?.ALARM?.length <= 3 &&
    clusterEvent?.INFO?.length <= 3 &&
    clusterEvent?.WARNING?.length <= 3;

  const createSection = (events, type, colorClass) => {
    if (events.length === 0) return "";

    return `
      <div class="event-section-${type}">
        <div class="event-header-${type}" style="background-color: var(--${colorClass});">
          <span 
            class="event-count" 
            style="color: ${type === "warning" ? "black" : "white"};"
          >
            ${events.length}
          </span>
        </div>
        <div class="tooltip-events-elements-container-${type}" id="tooltip-events-elements-container-${type}" style="
     display: flex; flex-direction: column; gap: 5px; overflow-y: ${
       isOverflowHidden ? "hidden" : "auto"
     }; overflow-x: hidden; padding-right: 4px; padding-top: 4px; ">
          ${sortByTimestamp(events)
            .map((e) => {
              return `
              <div
                class="eventTooltipCluster-${e.id}/${e.timestamp}"
                style="display: flex; color: black; white-space: nowrap; cursor: pointer; align-items: center; gap: 0px; padding: 0;"
                id="eventTooltipCluster-${e.id}/${e.timestamp}"
              >
                ${renderToString(
                  <>
                    <MapMarkerEventLocation
                      type={e.name}
                      hasTooltip={true}
                      eventsType={allEvents}
                      preferences={preferences}
                    />
                  </>
                )}
                <div style="position: relative; bottom: 4px; ">
                  <div style="font-size: 14px; font-family: Arial, sans-serif; line-height:14px; font-weight: 530">
                    ${t(`eventType.${e.name}`)}
                  </div>
                  <div style="font-size: 10px; line-height:14px; color: var(--global-colors-palette-light-grey); font-weight: 600">
                    ${ConvertTimeZone(
                      new Date(e.timestamp),
                      preferences.timeZone,
                      preferences.localeFormat
                    ).replace(",", " |")}
                  </div>
                </div>
              </div>
            `;
            })
            .join("")}
        </div>
      </div>
    `;
  };

  return `
    <style>
      .dashboard-container-location-history {
        font-family: Arial, sans-serif;
        background-color: var(--global-colors-ui-white);
        margin: 15px;
        display: flex;
        gap: 10px;
        flex-direction: row;
        width: fit-content;
        max-height: calc(3 * 56px);
      }

      .event-section-alarm, .event-section-warning, .event-section-info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: fit-content;
      }

      .event-header-alarm, .event-header-warning, .event-header-info {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 550;
        color: var(--global-colors-ui-white);
        position: sticky;
        top: 0;
        z-index: 99;
        background-color: var(--global-colors-ui-white);
      }

      .event-list-alarm, .event-list-warning, .event-list-info {
        display: flex;
        flex-direction: column;
        gap: 2px;
        overflow-y: ${isOverflowHidden ? "hidden" : "auto"};
        overflow-x: hidden;
      }
      
      .event-item {
        height: 56px;
      }

    </style>
    <div class="dashboard-container-location-history">
      ${createSection(clusterEvent.ALARM, "alarm", "global-colors-palette-red")}
      ${createSection(
        clusterEvent.WARNING,
        "warning",
        "global-colors-feedback-warning"
      )}
      ${createSection(clusterEvent.INFO, "info", "global-colors-ui-primary")}
    </div>
  `;
};

export const tooltipEventStyleForLocationHistory = (numberEventCategory) => {
  const thereAreMoreEvents = (numberEventCategory) => {
    switch (numberEventCategory) {
      case 1:
        return "230px";
      case 2:
        return "250x";
      case 3:
        return "700px";
      default:
        return "300px";
    }
  };

  return `
  .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        font-size: 12px !important;
        margin: 0px;
        min-width: fit-content !important;
        max-width: ${thereAreMoreEvents(numberEventCategory)};
        width: fit-content !important ;
        padding: 0px !important;
        border-radius: 4px;
        color: var(--global-colors-ui-white);
        background-color: var(--global-colors-ui-white) !important;
        opacity: 1;
        left: 0px;
        top: 14px;
        cursor: var(--global-cursor-default);
        box-shadow: 0px 0px 0px; 
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-t {
        bottom: 46px !important;
    }

    .gm-style .gm-style-iw-t::after {
      box-shadow: none ;
      margin-top: 0px;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 1;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
      margin-top: 17px;
      margin-left: 4px;
      background-color: var(--global-colors-ui-white) !important;
      width: 20px;
      height: 10px;
      opacity: 1;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 1;
    }

    .gm-style .gm-style-iw-c .button {
        transition: none;
    }

    .gm-style .gm-style-iw-c .button:last-child {
        margin-left: auto;
    }
  `;
};
// #endregion Tooltip event in location history

// #region Tooltip Cluster
export const clusterEventInfoTooltipStyle = (number) => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        background-color: transparent !important;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        color: var(--global-colors-ink-dark);
        margin-top: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 5px !important;
        border-radius: 4px;
        font-size: 12px !important;
        left: ${number && number.length > 2 ? "180px" : "155px"};
        top: 80px;
        opacity: 1;
        min-width: 250px !important;
        box-shadow: none !important;
    }

    .gm-style .gm-style-iw-t {
        bottom: 48px !important;
    }

    .gm-style .gm-style-iw-t::after {
        margin-top: 0px;
        font-size: 18px;
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: transparent;
        margin-top: 0px;
    }

    .gm-style .gm-style-iw-tc:hover {
        background-color: transparent;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 15px;
    }

    .gm-style .gm-style-iw-c .button {
        transition: none;
    }
  `;
};

export const clusterEventInfoTwoTooltipStyle = (number) => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        background-color: transparent !important;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        color: var(--global-colors-ink-dark);
        margin-top: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 5px !important;
        border-radius: 4px;
        font-size: 12px !important;
        left: ${number && number.length > 2 ? "180px" : "155px"};
        top: 110px;
        opacity: 1;
        min-width: 250px !important;
        box-shadow: none !important;
    }

    .gm-style .gm-style-iw-t::after {
        margin-top: 0px;
        font-size: 18px;
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: transparent;
        margin-top: 0px;
    }

    .gm-style .gm-style-iw-tc:hover {
        background-color: transparent;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 15px;
    }
  `;
};

export const clusterEventInfoThreeTooltipStyle = (number) => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        background-color: transparent !important;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        color: var(--global-colors-ink-dark);
        margin-top: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 5px !important;
        border-radius: 4px;
        font-size: 12px !important;
        left: ${number && number.length > 2 ? "180px" : "155px"};
        top: 130px;
        opacity: 1;
        min-width: 250px !important;
        box-shadow: none !important;
    }

    .gm-style .gm-style-iw-t::after {
        margin-top: 0px;
        font-size: 18px;
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: transparent;
        margin-top: 0px;
    }

    .gm-style .gm-style-iw-tc:hover {
        background-color: transparent;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 15px;
    }
  `;
};
// #endregion Tooltip Cluster
