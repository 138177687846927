import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Checkbox } from "../../ui/Forms/Checkbox";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { Slider } from "../../ui/Map/Slider";
import { Tag } from "../../ui/Tags/Tag";
import { datetoLocaleFormat } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  formatData,
  kmPerHToMilesPerH,
  kmPerLtToMilesPerG,
  kmToMiles,
  ltToGal,
  mtToKm,
  mtToMiles,
  numberAnnotation,
} from "../../utils/Utils";
import {
  Contract,
  contractsSelectors,
  getContractAsync,
} from "../contract/contractsSlice";
import {
  Device,
  devicesEmptyState,
  devicesSelectors,
} from "../device/devicesSlice";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
} from "../driver/driversSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import "./AdminVehicles.css";
import {
  Vehicle,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "./vehiclesSlice";
import {
  VehicleStatus,
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusEmptyState,
  vehiclesStatusSelectors,
} from "./vehiclesStatusSlice";

interface ViewVehicleProps {
  permissions: UserPermissions;
}

const getStateorNull = (state: boolean | undefined) => {
  if (state) {
    return t("common.statusOption.on");
  } else if (state !== undefined) {
    return t("common.statusOption.off");
  }
  return null;
};

const ViewVehicle: React.FC<ViewVehicleProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const vehicleStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehicleStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  const vehicleRoute = useMatch("/admin/vehicles/view/:fleetId/:vehicleId");

  const [id, setId] = useState<number>(-1);

  let vehicle: Vehicle =
    useAppSelector((state) => vehiclesSelectors.selectById(state, id)) ??
    ({} as Vehicle);
  let vehicleStatus: VehicleStatus =
    useAppSelector((state) =>
      vehiclesStatusSelectors.selectById(state, vehicle.vehicleStatus)
    ) ?? ({} as VehicleStatus);
  let driver: Driver =
    useAppSelector((state) =>
      driversSelectors.selectById(state, vehicle.driver)
    ) ?? ({} as Driver);
  let device: Device =
    useAppSelector((state) =>
      devicesSelectors.selectById(state, vehicle.device)
    ) ?? ({} as Device);
  let contract: Contract =
    useAppSelector((state) =>
      contractsSelectors.selectById(state, vehicle.contract)
    ) ?? ({} as Contract);

  useEffect(() => {
    // get vehicle id and fleet name
    if (vehicleRoute !== null) {
      if (vehicleRoute.params.vehicleId && vehicleRoute.params.fleetId) {
        setId(parseInt(vehicleRoute.params.vehicleId));
        store.dispatch(
          getVehicleAsync({
            fleetId: parseInt(vehicleRoute.params.fleetId),
            id: parseInt(vehicleRoute.params.vehicleId),
          })
        );
        return;
      }
    }
    ToastNotification({
      toastId: "unauthorizedError",
      status: "error",
      description: t("common.unauthorizedError"),
    });
    navigate(-1);
  }, [navigate, vehicleRoute]);

  useEffect(() => {
    if (vehicleStatusSliceStatus === "failed") {
      switch (vehicleStatusSliceStatus) {
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        case GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND:
          ToastNotification({
            toastId: "vehicleNotFoundError",
            status: "error",
            description: t("common.vehicleNotFoundError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/admin/vehicles");
    }
  }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode, navigate]);

  useEffect(() => {
    document.title = t("navigation.adminMenu.vehicles") + " - Admin";
    return function cleanUp() {
      store.dispatch(driversEmptyState());
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(vehiclesEmptyState());
      store.dispatch(devicesEmptyState());
    };
  }, []);

  document.title = useMemo(() => {
    if (!!vehicle?.alias && !!vehicle?.plate) {
      return (
        `${vehicle.alias} - ${vehicle.plate} - ` +
        t("navigation.adminMenu.vehicles") +
        " - Admin"
      );
    } else {
      return t("navigation.adminMenu.vehicles") + " - Admin";
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehicle && !_.isEmpty(vehicle) && vehicle?.device) {
      if (!(vehicle?.contract && contract.id === vehicle.contract)) {
        store.dispatch(getContractAsync({ deviceId: vehicle.device }));
      }
    }
  }, [vehicle]);

  return (
    <>
      {!!vehicle && !_.isEmpty(vehicle) && (
        <PageFilters>
          <div className="col col-16">
            <div className="ev-subtopbar">
              <div className="ev-subsection-name">
                {vehicle.alias} - {vehicle.plate}
              </div>
              <div className="ev-button-save-group">
                <Button
                  size="small"
                  aspect="secondary"
                  label={t("admin.vehicle.editView.view_all")}
                  onClick={() => navigate("/admin/vehicles")}
                />
                {permissions.vehicles.write && (
                  <Button
                    size="small"
                    aspect="primary"
                    onlyIcon={false}
                    label={t("common.edit")}
                    onClick={() => {
                      navigate(
                        "/admin/vehicles/edit/" +
                          vehicle.fleet +
                          "/" +
                          vehicle.id
                      );
                    }}
                  >
                    {" "}
                    <IconEdit
                      className=""
                      size={14}
                      color="var(--global-colors-ui-white)"
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </PageFilters>
      )}
      {vehicleStatusSliceStatus === "idle" && (
        <PageContent>
          <div className="ev-form-container">
            <div className="ev-general-info-form">
              <div className="ev-forms-title">
                {t("admin.vehicle.editView.general.title")}
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.general.id.label")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="id"
                      placeholder={
                        vehicle.id
                          ? t("admin.vehicle.editView.general.id.placeholder")
                          : t("common.na")
                      }
                      value={vehicle.id ?? null}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.general.alias.label")}*
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="alias"
                      placeholder={
                        vehicle.alias
                          ? t(
                              "admin.vehicle.editView.general.alias.placeholder"
                            )
                          : t("common.na")
                      }
                      value={vehicle.alias ?? null}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.general.model.label")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <div className="ev-left-form-col">
                      <TextField
                        id="year"
                        placeholder={
                          vehicle.registrationDate
                            ? t(
                                "admin.vehicle.editView.general.model.placeholder.year"
                              )
                            : t("common.na")
                        }
                        value={
                          vehicle.registrationDate
                            ? datetoLocaleFormat(
                                vehicle.registrationDate,
                                preferencesContext.localeFormat ?? undefined
                              )
                            : null
                        }
                      ></TextField>
                    </div>
                    <div className="ev-left-form-col">
                      <TextField
                        id="brand"
                        placeholder={
                          vehicle.brand
                            ? t(
                                "admin.vehicle.editView.general.model.placeholder.brand"
                              )
                            : t("common.na")
                        }
                        value={vehicle.brand ?? null}
                      ></TextField>
                    </div>
                    <div className="ev-right-form-col">
                      <TextField
                        id="model"
                        placeholder={
                          vehicle.model
                            ? t(
                                "admin.vehicle.editView.general.model.placeholder.model"
                              )
                            : t("common.na")
                        }
                        value={vehicle.model ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.general.fuel")}*
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="fuelType"
                      placeholder={
                        vehicle.fuelType
                          ? t("admin.vehicle.editView.general.fuel")
                          : t("common.na")
                      }
                      value={
                        vehicle.fuelType
                          ? String(
                              t("common.optionsEnum.fuel." + vehicle.fuelType)
                            )
                          : String(null)
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">{t("common.license")}</div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="plate"
                      placeholder={
                        vehicle.plate ? t("common.license") : t("common.na")
                      }
                      value={vehicle.plate ?? null}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.general.type")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <div className="ev-drpdwn-large">
                      <TextField
                        id="type"
                        placeholder={
                          vehicle.type
                            ? t("admin.vehicle.editView.general.type")
                            : t("common.na")
                        }
                        value={
                          vehicle.type
                            ? String(
                                t("common.optionsEnum.type." + vehicle.type)
                              )
                            : ""
                        }
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="ev-specs-info-form">
              <div className="ev-forms-title">
                {t("admin.vehicle.editView.specs.title")}
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {preferencesContext.isMetric
                    ? t("admin.vehicle.editView.specs.initialKms") + " (km)"
                    : t("admin.vehicle.editView.specs.initialMiles") + " (mi)"}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="initialKms"
                      placeholder={
                        preferencesContext.isMetric
                          ? t("admin.vehicle.editView.specs.initialKms")
                          : t("admin.vehicle.editView.specs.initialMiles")
                      }
                      value={
                        vehicle.initialKms
                          ? preferencesContext.isMetric
                            ? vehicle.initialKms
                            : kmToMiles(vehicle.initialKms)
                          : `0`
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {preferencesContext.isMetric
                    ? t("admin.vehicle.editView.specs.currentKms") + " (km)"
                    : t("admin.vehicle.editView.specs.currentMiles") + " (mi)"}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="currentKms"
                      placeholder={
                        preferencesContext.isMetric
                          ? t("admin.vehicle.editView.specs.currentKms")
                          : t("admin.vehicle.editView.specs.currentMiles")
                      }
                      value={
                        vehicleStatus.dynamicFields?.odometer
                          ? preferencesContext.isMetric
                            ? numberAnnotation(
                                mtToKm(vehicleStatus.dynamicFields?.odometer),
                                1
                              )
                            : formatData(
                                vehicleStatus.dynamicFields?.odometer,
                                mtToMiles,
                                preferencesContext,
                                1
                              )
                          : `${t("common.na")}`
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {preferencesContext.isMetric
                    ? t("admin.vehicle.editView.specs.consumption") + " (km/l)"
                    : t("admin.vehicle.editView.specs.consumption") + " (mpg)"}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="consumption"
                      placeholder={
                        vehicle.consumption
                          ? t("admin.vehicle.edit.specs.consumption")
                          : t("common.na")
                      }
                      value={
                        (preferencesContext.isMetric
                          ? numberAnnotation(Number(vehicle.consumption), 1)
                          : formatData(
                              Number(vehicle.consumption),
                              kmPerLtToMilesPerG,
                              preferencesContext,
                              1,
                              1
                            )) ?? null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.specs.fuelPrice") + " (€/l)"}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="fuelPrice"
                      placeholder={
                        vehicle?.fuelPrice
                          ? t("admin.vehicle.edit.specs.fuelPrice")
                          : t("common.na")
                      }
                      value={
                        numberAnnotation(Number(vehicle.fuelPrice), 3, 3) ??
                        null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.specs.co2.label")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="emission"
                      placeholder={
                        vehicle.emissions
                          ? t("admin.vehicle.editView.specs.co2.placeholder")
                          : t("common.na")
                      }
                      value={
                        numberAnnotation(Number(vehicle.emissions)) ?? null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {preferencesContext.isMetric
                    ? t("admin.vehicle.editView.specs.maxSpeed") + " (km/h)"
                    : t("admin.vehicle.editView.specs.maxSpeed") + " (mph)"}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="speedMax"
                      placeholder={
                        vehicle.maxSpeed
                          ? t("admin.vehicle.edit.specs.maxSpeed")
                          : t("common.na")
                      }
                      value={
                        (preferencesContext.isMetric
                          ? numberAnnotation(Number(vehicle.maxSpeed), 0)
                          : formatData(
                              Number(vehicle.maxSpeed),
                              kmPerHToMilesPerH,
                              preferencesContext,
                              0
                            )) ?? null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.specs.stopTimeThreshold") +
                    " (sec)"}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="stopTimeThreshold"
                      placeholder={
                        vehicle.stopTimeThreshold
                          ? t("admin.vehicle.edit.specs.stopTimeThreshold")
                          : t("common.na")
                      }
                      value={
                        numberAnnotation(
                          Number(vehicle.stopTimeThreshold),
                          0
                        ) ?? null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {preferencesContext.isMetric
                    ? t("admin.vehicle.editView.specs.fuelCapacity") + " (lt)"
                    : t("admin.vehicle.editView.specs.fuelCapacity") + " (gal)"}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="fuelCapacity"
                      placeholder={
                        vehicle.fuelCapacity
                          ? t("admin.vehicle.edit.specs.fuelCapacity")
                          : t("common.na")
                      }
                      value={
                        (preferencesContext.isMetric
                          ? numberAnnotation(Number(vehicle.fuelCapacity), 0)
                          : formatData(
                              Number(vehicle.fuelCapacity),
                              ltToGal,
                              preferencesContext,
                              0
                            )) ?? null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
            </div>
            <div className="ev-driver-info-form">
              <div className="ev-forms-title">
                {t("admin.vehicle.editView.driver.title")}
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.driver.id.label")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="driverId"
                      placeholder={
                        driver.id
                          ? t("admin.vehicle.editView.driver.id.placeholder")
                          : t("common.na")
                      }
                      value={driver.id ?? null}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.driver.name")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="plate"
                      placeholder={
                        driver.firstName && driver.lastName
                          ? t("admin.vehicle.editView.driver.name")
                          : t("common.na")
                      }
                      value={
                        driver.firstName && driver.lastName
                          ? driver.firstName + " " + driver.lastName
                          : null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.driver.tachograph.label")}
                </div>
                <Form>
                  <div className="ev-forms">
                    <TextField
                      id="tachoGraph"
                      placeholder={
                        driver.tachographCard
                          ? t(
                              "admin.vehicle.editView.driver.tachograph.placeholder"
                            )
                          : t("common.na")
                      }
                      disabled={true}
                      value={driver?.tachographCard ?? null}
                    ></TextField>
                  </div>
                </Form>
              </div>
            </div>
            <div className="ev-device-info-form">
              <div className="ev-forms-title">
                {t("admin.vehicle.editView.device.title")}
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">{t("common.device")}</div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="deviceId"
                      placeholder={
                        device.id ? t("common.device") : t("common.na")
                      }
                      value={device.id ?? null}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.device.model.label")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="deviceModel"
                      placeholder={
                        device.modelName
                          ? t("admin.vehicle.editView.device.model.placeholder")
                          : t("common.na")
                      }
                      value={device.modelName ?? null}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">{t("common.imei")}</div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="deviceImei"
                      placeholder={
                        device.imei ? t("common.imei") : t("common.na")
                      }
                      value={device.imei ?? null}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.device.fineService.label")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <Checkbox
                      label={t(
                        "admin.vehicle.editView.device.fineService.option"
                      )}
                      subLabel={t(
                        "admin.vehicle.editView.device.fineService.placeholder"
                      )}
                      checked={device.fineServiceEnabled ?? false}
                      readOnly={true}
                    ></Checkbox>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.device.speaker")}
                </div>
                <Form>
                  <div className="ev-forms ev-forms-slider ev-only-view">
                    <Slider
                      data={{ min: "0", max: "100" }}
                      valueLabel="%"
                      val={device.speakerVolumePercentage ?? 0}
                    />
                  </div>
                </Form>
              </div>
            </div>
            <div className="ev-service-info-form">
              <div className="ev-forms-title">
                {t("admin.vehicle.editView.service.title")}
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.service.name")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="serviceName"
                      placeholder={
                        contract?.service
                          ? t("admin.vehicle.editView.service.name")
                          : t("common.na")
                      }
                      value={contract?.service ? contract?.service : null}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.service.activation")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="serviceActivation"
                      placeholder={
                        contract.activationDate
                          ? t("admin.vehicle.edit.service.activation")
                          : t("common.na")
                      }
                      value={
                        contract.activationDate
                          ? datetoLocaleFormat(
                              contract.activationDate,
                              preferencesContext.localeFormat ?? undefined
                            )
                          : null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.service.expiration")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="serviceExpiration"
                      placeholder={
                        contract.expirationDate
                          ? t("admin.vehicle.edit.service.expiration")
                          : t("common.na")
                      }
                      value={
                        contract.expirationDate
                          ? datetoLocaleFormat(
                              contract.expirationDate,
                              preferencesContext.localeFormat ?? undefined
                            )
                          : null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.service.credits.label")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view ev-forms-credits">
                    <TextField
                      id="credits"
                      placeholder={
                        contract.availableCredits
                          ? t("admin.vehicle.editView.service.credits.label")
                          : t("common.na")
                      }
                      value={
                        contract.availableCredits
                          ? contract.availableCredits + "/100"
                          : null
                      }
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.service.threshold")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="thresold"
                      placeholder={
                        getStateorNull(contract.threshold) !== null
                          ? t("admin.vehicle.editView.service.threshold")
                          : t("common.na")
                      }
                      value={getStateorNull(contract.threshold)}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.service.status")}
                </div>
                <Form>
                  <div className="ev-forms ev-only-view">
                    <TextField
                      id="serviceStatus"
                      placeholder={
                        getStateorNull(contract.status) !== null
                          ? t("admin.vehicle.editView.service.status")
                          : t("common.na")
                      }
                      value={getStateorNull(contract.status)}
                    ></TextField>
                  </div>
                </Form>
              </div>
              <div className="ev-tag-forms-container">
                <div className="ev-forms-label">
                  {t("admin.vehicle.editView.service.associated")}
                </div>
                <div className="ev-forms">
                  {contract?.service &&
                    contract?.options.map((option) => {
                      return (
                        <Tag
                          key={option.name}
                          className="edit-tags"
                          text={t(`serviceOption.${option.name}.name`)}
                          size="small"
                          mode="outline"
                          type="neutro"
                        ></Tag>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </PageContent>
      )}
    </>
  );
};
export default ViewVehicle;
