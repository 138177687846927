import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import {
  Contract,
  contractsSelectors,
  getContractsAsync,
} from "../contract/contractsSlice";
import { Device, devicesSelectors } from "../device/devicesSlice";
import { Driver, driversSelectors } from "../driver/driversSlice";
import {
  DriverStatus,
  driversStatusEmptyState,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import { FleetView, fleetViewsSelectors } from "../fleet/fleetViewsSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import {
  Preset,
  getPresetsAsync,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../users/preset/presetsSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import "./DashboardVehicles.css";
import { DashboardVehiclesFilterBar } from "./DashboardVehiclesFilterBar";
import { DashboardVehiclesTableBuilder } from "./DashboardVehiclesTableBuilder";
import {
  Vehicle,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "./vehiclesSlice";
import {
  VehicleStatus,
  getFilteredVehiclesStatusPaginationAsync,
  selectVehiclesStatusSlicTotalElements,
  selectVehiclesStatusSlicePage,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusEmptyState,
  vehiclesStatusSelectors,
} from "./vehiclesStatusSlice";

interface DashboardVehiclesProps {
  permissions: UserPermissions;
}

const context = "vehicleDashboard";

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

export const DashboardVehicles: React.FC<DashboardVehiclesProps> = ({
  permissions,
}) => {
  const tableBuilderRef = useRef<DashboardVehiclesTableBuilder>(
    new DashboardVehiclesTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [resetPage, setResetPage] = useState<boolean>(false);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  let vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);

  const vehiclesStatusSlicePages = useAppSelector(
    selectVehiclesStatusSlicePage
  );

  const vehiclesStatusSliceTotalElements = useAppSelector(
    selectVehiclesStatusSlicTotalElements
  );
  const contracts = useAppSelector(contractsSelectors.selectAll);
  const vehicleStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehicleStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );
  const driverStatusSliceStatus = useAppSelector(selectDriverStatusSliceStatus);
  const isVehicleStatusIdle = vehicleStatusSliceStatus === "idle";
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const [movePage, setMovePage] = useState(false);
  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isVehicleStatusIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isVehicleStatusIdle, queryParamsChanged]);

  const driverStatusSliceStatusReasonCode = useAppSelector(
    selectDriverStatusSliceReasonCode
  );
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);

  // Chiamata API
  useEffect(() => {
    document.title = t("navigation.userMenu.vehicles");
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));
    return function cleanUp() {
      store.dispatch(vehiclesEmptyState());
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(driversStatusEmptyState());
    };
  }, []);

  useEffect(() => {
    if (
      vehicles.length > 0 &&
      isVehicleStatusIdle &&
      (queryParamsChanged || movePage)
    ) {
      movePage && setMovePage(!movePage);
      const driverIds = vehicles.map((x) => x.driver).filter((x) => !!x);
      driverIds &&
        driverIds.length > 0 &&
        store.dispatch(
          getFilteredDriversStatusAndDetailsAsync(
            getQueryString({ id: driverIds })
          )
        );
    }
  }, [vehicles, isVehicleStatusIdle, queryParamsChanged, movePage]);

  useEffect(() => {
    if (vehicles.length > 0) {
      const vehicleIds = vehicles.map((x) => x.id);
      const queryParams = getQueryString({ "vehicle.id": vehicleIds });
      store.dispatch(getContractsAsync({ queryParams: queryParams }));
    }
  }, [vehicles]);

  useEffect(() => {
    if (
      driverStatusSliceStatus === "failed" &&
      driverStatusSliceStatusReasonCode ===
        GTFleetErrorCodes.DRIVER_STATUS_NOT_FOUND
    ) {
      console.error(t("common.driverStatusError"));
      ToastNotification({
        toastId: "driverStatusError",
        status: "error",
        description: t("common.driverStatusError"),
      });
    } else if (
      driverStatusSliceStatus === "failed" &&
      driverStatusSliceStatusReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driverStatusSliceStatus, driverStatusSliceStatusReasonCode]);

  useEffect(() => {
    if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode ===
        GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND
    ) {
      console.error(t("common.vehicleStatusError"));
      ToastNotification({
        toastId: "vehicleStatusError",
        status: "error",
        description: t("common.vehicleStatusError"),
      });
    } else if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode]);

  if (presetsSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    //#region Table
    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
              sort: element === "vehicle" ? true : false,
            };
          })
        : []
    );

    tableData.rows =
      vehicleStatusSliceStatus === "idle" &&
      driverStatusSliceStatus === "idle" &&
      presetsSliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? vehicles.map((vehicle: Vehicle) => {
            let driver: Driver =
              driversSelectors.selectById(store.getState(), vehicle.driver) ??
              ({} as Driver);
            let driverStatus: DriverStatus =
              driversStatusSelectors.selectById(
                store.getState(),
                vehicle.driver
              ) ?? ({} as DriverStatus);
            let vehicleStatus: VehicleStatus =
              vehiclesStatusSelectors.selectById(
                store.getState(),
                vehicle.vehicleStatus
              ) ?? ({} as VehicleStatus);
            let fleetView: FleetView =
              fleetViewsSelectors.selectById(store.getState(), vehicle.fleet) ??
              ({} as FleetView);
            let device: Device =
              devicesSelectors.selectById(store.getState(), vehicle.device) ??
              ({} as Device);
            let contract: Contract =
              contractsSelectors.selectById(
                store.getState(),
                vehicle.contract
              ) ?? ({} as Contract);
            return tableBuilder.rowsBuilder(
              tableData.columns,
              {
                vehicle,
                driver,
                driverStatus,
                vehicleStatus,
                fleetView,
                device,
                contract,
              },
              preferencesContext
            );
          })
        : tableData.rows;
  }
  //#endregion Table

  // loader for PageCount
  const isLoading = vehicleStatusSliceStatus === "loading";

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <DashboardVehiclesFilterBar
            presets={presets}
            callback={setQueryParamsFromFilterBar}
          />
        </div>
      </PageFilters>
      {queryParamsFromFilterBar !== "" && tableData.rows?.length > 0 && (
        <PageContent>
          <div className="vehicles-table">
            <Table data={tableData}>
              <Table.Head hasTableSpace={true} />
              <Table.Body />
            </Table>
          </div>
          <div className="vehicles-pagination">
            <PageCounter
              isActionPerforming={isLoading}
              totalElements={vehiclesStatusSliceTotalElements}
              resetPage={resetPage}
              disabled={!isVehicleStatusIdle}
              numOfPages={vehiclesStatusSlicePages}
              onClick={(id, currentPage) => {
                if (id !== currentPage) {
                  setMovePage(true);
                  const pageAndSize = getQueryString({
                    page: id - 1,
                    size: "10",
                  });
                  const finalQueryParams = queryParamsFromFilterBar
                    ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                    : pageAndSize;
                  store.dispatch(
                    getFilteredVehiclesStatusPaginationAsync({
                      queryParams: finalQueryParams,
                    })
                  );
                }
              }}
            />
          </div>
        </PageContent>
      )}
    </>
  );
};
